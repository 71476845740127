import React from 'react';
import { MainHeader } from './MainHeader';
import { Layout } from 'antd';

const { Content, Footer } = Layout;

export const AppLayout = ({ children }) => (
  <Layout style={{ height: '100%', textAlign: 'center' }}>
    <MainHeader />
    <Content
      style={{
        padding: '16px',
        marginTop: '80px',
        minHeight: 'calc(100vh - 150px)',
        display: 'flex',
        position: 'relative',
      }}
    >
      {children}
    </Content>
    <Footer>ExoMindset ©2024</Footer>
  </Layout>
);
