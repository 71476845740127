import common from './common.json';
import preOffer from './preOffer.json';
import offer from './offer.json';
import error from './error.json';
import position from './position.json';
import candidate from './candidate.json';

const es = {
  candidate,
  common,
  preOffer,
  offer,
  position,
  error,
};

export default es;
