import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import { AppLayout } from '../layout/Layout';
import { NotFoundPage } from '../pages/notFound/NotFoundPage';

const JobProposalPage = lazy(() =>
  import('../pages/jobProposal/JobProposalPage')
);
const JobPositionPage = lazy(() =>
  import('../pages/jobPosition/JobPositionPage')
);
// const CandidatePage = lazy(() => import('../pages/candidate/CandidatePage'));

const routesArray = [
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: '/',
    element: <Navigate to="/job-position" />,
  },
  {
    path: 'job-proposal/*',
    element: <JobProposalPage />,
  },
  {
    path: 'job-position/*',
    element: <JobPositionPage />,
  },
  // {
  //   path: 'candidate/*',
  //   element: <CandidatePage />,
  // },
];

const AppRoutes = () => {
  const routes = useRoutes(routesArray);
  return routes;
};

export const Loading = () => (
  <Spin
    spinning
    style={{
      width: '100%',
      height: '100%',
      justifySelf: 'center',
      alignSelf: 'center',
    }}
  />
);

export const Router = () => (
  <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
    <AppLayout>
      <Suspense fallback={<Loading />}>
        <AppRoutes />
      </Suspense>
    </AppLayout>
  </BrowserRouter>
);
