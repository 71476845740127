import React, { useState } from 'react';
import {
  Row,
  Col,
  Layout,
  Tooltip,
  Radio,
  Dropdown,
  Button,
  Typography,
} from 'antd';
import logoExo from '../assets/logo_exo.png';
import './layoutStyle.css';
import { useTranslation } from 'react-i18next';

const { Header } = Layout;
const { Text } = Typography;

export const MainHeader = () => {
  const languagesMap = {
    es: 'Español',
    en: 'English',
  };
  const { t, i18n } = useTranslation('common');
  const [language, setLanguage] = useState(languagesMap[i18n.language]);

  const selectLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(languagesMap[language]);
  };

  const items = [
    {
      value: 'es',
      label: (
        <Text onClick={() => selectLanguage('es')}>
          {t('languages.spanish')}
        </Text>
      ),
    },
    {
      value: 'en',
      label: (
        <Text onClick={() => selectLanguage('en')}>
          {t('languages.english')}
        </Text>
      ),
    },
  ];

  return (
    <Header
      style={{
        backgroundColor: 'white',
        fontSize: 12,
        color: '#5847B2',
        fontWeight: 'bold',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
        position: 'fixed',
        zIndex: 10,
        width: '100%',
        height: '80px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
        <div className="testing-badge">STAGING</div>
      )}
      <Row style={{ width: '100%' }} justify="space-between">
        <Col>
          <Tooltip title={t('goToMainPage', { ns: 'common' })}>
            <a href="https://exomindset.co">
              <img
                src={logoExo}
                style={{ height: '40px' }}
                alt="Exomindset logo"
                className="logo"
              />
            </a>
          </Tooltip>
        </Col>
        <Col>
          <Dropdown menu={{ items }} placement="bottomLeft">
            <Button>{language}</Button>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};
